/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

import { editClueStatus } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import { useCluesData } from "src/utils/useData";
import { ClueProps } from "../Play/Clue/types";
import { getClueStatus } from "../Play/Clue/utils";
import "./qr.scss";

const useGetUnlockedClueByQr = (qrId: string) => {
  const actions = useStoreActions({ editClueStatus });
  const { getUnlockedClueByQr, getUnlockedClues, clearUnlockedClues } =
    useClueActions();
  const [isDataReady, setIsDataReady] = useState(false);
  const {
    getUnlockedClueByQrData: { data, loading },
    getUnlockedCluesData: { data: unlockedCluesData },
  } = useCluesData();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQr = async () => {
      clearUnlockedClues();
      await getUnlockedClueByQr(qrId);
      setIsDataReady(true);
    };
    fetchQr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrId]);

  const fetchUnlockedClues = async () => {
    const response = await getUnlockedClues(data.lamp_id);
    return response;
  };

  const handleClueDetail = async (clue: ClueProps | undefined) => {
    const updatedStatus = [
      {
        id: clue?.id || 0,
        is_current: true,
        status: getClueStatus(clue?.answer_status || "not-viewed"),
        locked: clue?.locked || 1,
      },
    ];

    await actions.editClueStatus(
      updatedStatus,
      clue?.locked === 1 ? true : false
    );

    navigate(`/play/clues?region=${data.region.id}&season=${data.season_id}`, {
      replace: true,
      state: {
        tab: "unlocked-clues",
      },
    });
  };

  useEffect(() => {
    const onFetchUnlockedClues = async () => {
      if (data?.intent === "locked_clue" && isDataReady) {
        await fetchUnlockedClues();
        const clue = unlockedCluesData.find((clue) => clue.id === data.clue_id);
        await handleClueDetail(clue);
      }
    };

    if (data && unlockedCluesData) {
      onFetchUnlockedClues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlockedCluesData, data, isDataReady]);

  return { loading };
};

const QrPage = () => {
  const { qrId } = useParams();

  const { loading } = useGetUnlockedClueByQr(qrId || "");

  if (loading) {
    return (
      <section className="container">
        <Spinner center color="white" size={128} />
      </section>
    );
  }

  return (
    <section className="container">
      <h1>success!</h1>
    </section>
  );
};

export default QrPage;
