import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ClueStatusProps } from "src/api/clue";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getClueStatus, getCurrentClue } from "src/pages/Play/Clue/utils";
import {
  editClueStatus,
  setClueModalState,
  setSeasonInfo,
} from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import { useCluesData, useRegionData } from "src/utils/useData";
import { isClueCompleted } from "src/utils/utils";
import ClueGridView from "./ClueGridView";
import ClueListView from "./ClueListView";
import classes from "./styles.module.scss";

type Props = {
  view: "list" | "grid";
  allFound: boolean;
  loading: boolean;
  tab: string;
};

const Clues: React.FC<Props> = ({
  view = "list",
  allFound,
  loading = false,
  tab,
}) => {
  const [searchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";
  const actions = useStoreActions({
    setClueModalState,
    setSeasonInfo,
    editClueStatus,
  });

  const { getClues, getUnlockedClues } = useClueActions();
  const { region: regionData } = useRegionData();
  const { seasons = [] } = regionData.data;

  const currentSeason = searchParams.get("season") || "1";

  const selectedSeason = seasons.find(
    (season) => season?.id?.toString() === currentSeason
  );
  const lampId = selectedSeason?.lamp?.id ?? 1;
  const {
    clues: { data: clues },
    getUnlockedCluesData: { data: unlockedCluesData },
  } = useCluesData();

  const currentClue = getCurrentClue(
    tab === "unlocked-clues" ? unlockedCluesData : clues
  );
  useEffect(() => {
    actions.setClueModalState(false, null);
    getUnlockedClues(lampId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lampId]);

  const updatedClues = useMemo(() => {
    if (tab === "completed-clues") {
      return (
        clues.filter((clue) => {
          return isClueCompleted(clue);
        }) || []
      );
    } else if (tab === "not-completed-clues") {
      return (
        clues.filter((clue) => {
          return !isClueCompleted(clue);
        }) || []
      );
    } else if (tab === "unlocked-clues") {
      return unlockedCluesData || [];
    }
    return clues || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, clues, unlockedCluesData]);

  useEffect(() => {
    const activeIndex = updatedClues.findIndex(
      (clue) => clue.id === currentClue?.id
    );

    if (activeIndex === -1 && updatedClues.length) {
      handleMissingCurrentClue(updatedClues);
    } else {
      if (clues.length > 0 && tab !== "unlocked-clues") {
        handleActiveClueNotFound(clues);
      }

      if (unlockedCluesData.length > 0 && tab === "unlocked-clues") {
        handleActiveClueNotFound(unlockedCluesData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, lampId, clues, unlockedCluesData]);

  const updateClueStatus = async (updatedStatus: ClueStatusProps[]) => {
    await actions.editClueStatus(updatedStatus);
    await getClues(`${regionId}`, { season });
  };

  const handleMissingCurrentClue = (updatedClues: ClueProps[]) => {
    const isAllCluesLocked = updatedClues.every(
      (clue) => clue.answer_status === "locked"
    );

    if (isAllCluesLocked) return;
    const firstAvailableClue = updatedClues.find(
      (clue) => clue.answer_status !== "locked"
    );
    const currentClue = firstAvailableClue || updatedClues[0];
    const updatedStatus = [
      {
        id: currentClue.id,
        status: getClueStatus(currentClue.answer_status),
        is_current: true,
        locked: currentClue.locked,
      },
    ];
    updateClueStatus(updatedStatus);
  };

  const handleActiveClueNotFound = (clues: ClueProps[]) => {
    const availableClues = clues.filter(
      (clue) => clue.answer_status !== "locked"
    );

    const activeClue = availableClues.find(
      (clue) => clue.is_current || clue.answer_status === "active"
    );

    const currentClue = availableClues[0];
    if (!activeClue) {
      const updatedStatus = [
        {
          id: currentClue.id,
          status: getClueStatus(currentClue.answer_status),
          is_current: true,
          locked: currentClue.locked,
        },
      ];
      updateClueStatus(updatedStatus);
    }
  };
  const getView = () => {
    switch (view) {
      case "grid":
        return <ClueGridView clues={updatedClues} allFound={allFound} />;

      default:
        return (
          <ClueListView clues={updatedClues} allFound={allFound} tab={tab} />
        );
    }
  };
  return (
    <>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      {getView()}
    </>
  );
};

export default Clues;
